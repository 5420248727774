import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/LogoInvert';
import Image from '../elements/Image';
import FooterNav from './partials/FooterNav';
import FooterSocial from './partials/FooterSocial';
import Copyright from '../elements/Copyright';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

class Footer extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      topDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-footer invert-color',
      topOuterDivider && 'has-top-divider',
      className
    );

    return (
      <footer
        {...props}
        className={classes}
      >
        <div className="container">
          <div className={
            classNames(
              'site-footer-inner',
              topDivider && 'has-top-divider'
            )}>
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <Logo className="mb-16" />
                  <Copyright />
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Company</div>
                  <p>555 Flower St. STE B10<br />Los Angeles, CA 90071<br />949-278-0339</p>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="https://shopify.com?ref=kamari-labs">Certified Shopify Partners</a>
                    </li>
                    <li>
                      {/* <a href="#">Download our Shopify Theme</a> */}
                    </li>
                  </ul>
                  <Image
                  src={require('../../assets/images/shopify-experts-kamari-labs.png')}
                  alt="Kamari Labs - Shopify Partners"
                  className="grayscale"
                  width={200}
                  height={64} />
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Resources</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://web.uri.edu/risbdc/wp-content/uploads/sites/987/Shopify-101.pdf">Shopify Marketing 101 Guide</a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.shopify.com/blog/make-your-first-sale?ref=kamari-labs">How To Make Your First Ecommerce Sale (2024) Guide</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Docs</div>
                  <ul className="list-reset mb-0">
                    <li>
                      {/* <a href="#">Coming Soon...</a> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom space-between center-content-mobile text-xxs">
              <FooterNav />
              <FooterSocial />
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
