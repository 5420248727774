import React from 'react';
import ReactGA from 'react-ga4';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Input from '../elements/Input';


// import some required elements
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import {firebaseapp } from "../../firebase";
// Initialize Firebase DB
const db = getFirestore(firebaseapp);

ReactGA.initialize("G-5P73B0T6R8");

class PartnerForm extends React.Component {

  constructor() {
    super();
    this.myRef = React.createRef();
  }



  componentDidMount = async () => {
    this.myRef.current.addEventListener('click', this.handleClick);
  }

  componentWillUnmount = async () => {
    this.myRef.current.removeEventListener('click', this.handleClick);
  }

  handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    ReactGA.event({
      category: "PartnerSignup",
      name: "PartnerSignup",
      action: "submit",
      label: "PartnerSignup",
      partner_signup: 1
    });

    const data = new FormData(document.getElementById("partners_form"));
    // console.log("data",data);

    try {

      const email = data.get("email");
      if(email === "" || email === null){
        alert("Email is required");
        return false;
      }
      const date = new Date();
      const json = {
        "email": email,
        "business_name": data.get("business_name"),
        "first_name": data.get("first_name"),
        "last_name": data.get("last_name"),
        "partner_type": data.get("partner_type"),
        "signup_at": date.toISOString(),
        "invite_sent": 0
      };
      const docRef = await addDoc(collection(db, "partner_submissions"), json);

      console.log("Document written with ID: ", [docRef.id]);

      // take them to a thank you page here.
      window.location.href = "/partners/thank-you";
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  render() {


    const {
      topDivider,
      bottomDivider
    } = this.props;

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Partner with Kamari Labs',
      paragraph: 'Work with us to make eCommerce better.'
    };

    return (
      <section className="has-bg-color">

      <div className="container">
          <div className={innerClasses}>
            <SectionHeader tag="h1" data={sectionHeader} className="center-content invert-color" />

            <div className="tiles-wrap">
            <div className="tiles-item tiles-item-no-pad partners-form-heading">

                  <Image
                  src={require('../../assets/images/logo.svg')}
                  alt="Kamari Labs - Shopify Partners"
                  width={64}
                  height={64} />
                  <h4>Register as a partner and - get started working with us</h4>

              </div>
              <div className="tiles-item tiles-item-no-pad">
                <div className="tiles-item-inner">
                  <form action="#" id="partners_form">
                    <div className="mb-12">
                      <Input type="text" placeholder="Business Name" id="business_name" name="business_name" required />
                    </div>
                    <div className="mb-12">
                      <Input type="email" placeholder="Email" id="email" name="email" required />
                    </div>
                    <div className="mb-12">
                      <Input type="text" placeholder="First Name" id="first_name" name="first_name" required />
                    </div>
                    <div className="mb-12">
                      <Input type="text" placeholder="Last Name" id="last_name" name="last_name" required />
                    </div>
                    <div className="mb-12">
                      <select name="partner_type" id="partner_type" required className="form-select">
                        <option value="Shopify Expert">Shopify Expert</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                    <div className="mb-12">
                      <input type="submit" id="submit" name="submit" value="Register" ref={this.myRef} className="button button-secondary" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </section>

    );
  }
}

export default PartnerForm;


