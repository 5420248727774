// Used for partner intake
import React from 'react';
import PartnerForm from "../components/sections/PartnerForm";

class Partners extends React.Component {
  render() {
    return (
      <React.Fragment>
        <PartnerForm className="has-bg-color-cut illustration-section-02" />
      </React.Fragment>
    );
  }
}


export default Partners;

