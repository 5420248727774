// Used for partner intake
import React from 'react';
import classNames from 'classnames';
import SectionHeader from '../components/sections/partials/SectionHeader';
import Image from '../components/elements/Image';

class PartnersThankYou extends React.Component {
  render() {

    const sectionHeader = {
      title: 'We got it!',
      paragraph: 'A member of our team will be in touch shortly.'
    };

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    return (


<section className="has-bg-color">

<div className="container">
    <div className={innerClasses}>
      <SectionHeader tag="h1" data={sectionHeader} className="center-content invert-color" />

      <div className="tiles-wrap">
      <div className="tiles-item tiles-item-no-pad partners-form-heading">

            <Image
            src={require('../../src/assets/images/logo.svg')}
            alt="Kamari Labs - Shopify Partners"
            width={64}
            height={64} />
            <h4>Thanks for the signup! We hope to work with you in the future.</h4>

        </div>
        <div className="tiles-item tiles-item-no-pad">
          <div className="tiles-item-inner">
          <h4>Thank you</h4>
          <p>We have received your request to become a partner. <br />We will contact you soon after we review your submission.</p>
          <a href="/" class="dark">kamarilabs.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>
    );
  }
}


export default PartnersThankYou;

