import React from 'react';

const LinkOut = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer-copyright">&copy; {year} Kamari Labs, all rights reserved</div>
  );
}

export default LinkOut;



