import React from 'react';

const LinkOut = ({
  className,
  ...props
}) => {

  props.href += "?ref=kamari-labs";
  return (
    <a href={props.href} rel="noopener noreferrer" target="_blank">{props.text}</a>
  );
}

export default LinkOut;
