import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';

// import some required elements
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import {firebaseapp } from "../../firebase";
// Initialize Firebase DB
const db = getFirestore(firebaseapp);

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

class Cta extends React.Component {

  componentDidMount = async () => {
    document.getElementById("newsletter_submit").addEventListener('click', this.handleClick);
  }

  componentWillUnmount = async () => {
    document.getElementById("newsletter_submit").removeEventListener('click', this.handleClick);
  }

  handleClick = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const data = new FormData(document.getElementById("newsletter"));
    // console.log("data",data);

    try {
      const docRef = await addDoc(collection(db, "newsletter"), {
        "email": data.get("email")
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Thank you for joining our newsletter!");
      document.getElementById("newsletter").reset();
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error adding you to our newsletter. Try again later.");
    }

  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h3 className="m-0">
                Keep update with the latest Shopify marketing trends and changes.
              </h3>
            </div>
            <div className="cta-action">
              <form id="newsletter" action="#">
              <Input type="email" className="newsletter_input" name="email" label="Subscribe" labelHidden hasIcon="right" placeholder="Your best email" />
              <Button id="newsletter_submit">
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#ffffff" />
                </svg>
              </Button>
              </form>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
